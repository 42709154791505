import { ReactComponent as WhatWeDoImageOne } from "./assets/images/what-we-do-1.svg";
import { ReactComponent as WhatWeDoImageTwo } from "./assets/images/what-we-do-2.svg";
import { ReactComponent as WhatWeDoImageThree } from "./assets/images/what-we-do-3.svg";
export const WHAT_WE_DO = [
  {
    id: "679efb57-e548-41b9-9784-73e090db0449",
    title: "Digital VAS Services",
    description:
      "We offer comprehensive portfolio of Value Added Services product line",
    image: <WhatWeDoImageOne />,
  },
  {
    id: "288c689a-e079-405e-b996-ce79a52c7ead",
    title: "Software Development",
    description:
      "Hassle-free development of  Software that will help your business grow.",
    image: <WhatWeDoImageTwo />,
  },
  {
    id: "1dd29ad7-8896-46c4-8bd6-6f1c5390f61b",
    title: "Digital Marketing",
    description:
      "Outsource your social media presence to our team and grow your channels organically.",
    image: <WhatWeDoImageThree />,
  },
];
