import { useState, useRef } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import CoverBackgroundImage from "../../assets/images/cover.jpg";
import SolutionsBackgroundImage from "../../assets/images/solutions_bg.jpeg";
import LogoImage from "../../assets/images/logo.png";
import LogoIcon from "../../assets/images/logo-icon.png";
import FooterBackgroundImage from "../../assets/images/footer_bg.svg";
import { WHAT_WE_DO } from "../../data";

const Home = () => {
  const [showNavContent, setShowNavContent] = useState(false);
  const whatWeDoRef = useRef();
  const aboutRef = useRef();
  const contactsRef = useRef();

  return (
    <main>
      <header
        style={{
          backgroundImage: `linear-gradient(rgba(50,50,50,0.6), rgba(50,50,50,0.7)),url(${CoverBackgroundImage})`,
        }}
        className="flex flex-col bg-cover bg-center bg-no-repeat px-8 md:px-16 py-10 text-white min-h-screen "
      >
        <nav className=" md:flex md:items-center md:justify-between">
          <div className="flex justify-between items-center mb-4 md:mb-0">
            <img src={LogoImage} alt="Website Logo" className="w-52" />
            {showNavContent ? (
              <AiOutlineClose
                size={25}
                className="md:hidden"
                onClick={() => setShowNavContent(false)}
              />
            ) : (
              <AiOutlineMenu
                size={25}
                className="md:hidden"
                onClick={() => setShowNavContent(true)}
              />
            )}
          </div>
          <div
            className={`${
              showNavContent ? "visible" : "hidden"
            } md:visible md:flex md:gap-4 cursor-pointer font-asap-medium space-y-2 md:space-y-0`}
          >
            <h3
              className="text-xl  hover:text-orange-400"
              onClick={() =>
                whatWeDoRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              What we do
            </h3>
            <h3
              className="text-xl  hover:text-orange-400"
              onClick={() =>
                aboutRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              About
            </h3>
            <h3
              className="text-xl hover:text-orange-400"
              onClick={() =>
                contactsRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Contacts
            </h3>
          </div>
        </nav>
        <div className="self-center space-y-4 md:space-y-10 flex-grow flex flex-col justify-center">
          <h2 className=" text-3xl w-2/3  md:text-7xl font-asap-bold">
            We integrate people and Mobile Content through Technology
          </h2>
          <p className="text-lg w-2/3 font-asap-medium">
            With awesome content, personalized user experience and data-driven
            decision making, Technolab opens up new opportunities to carriers
            through innovative business contents.
          </p>
        </div>
      </header>
      <section ref={whatWeDoRef} className="px-8 md:px-16 py-10">
        <div className="my-8">
          <h2 className="text-4xl font-asap-bold text-purple-800">
            What we do
          </h2>
        </div>
        <div className="grid  md:grid-cols-2 lg:grid-cols-3 gap-4">
          {WHAT_WE_DO.map((item) => (
            <article
              key={item.id}
              className="flex flex-col items-center border rounded shadow px-8 py-20 space-y-2 hover:scale-105 cursor-pointer transition duration-200 "
            >
              {item.image}
              <h3 className="text-2xl font-asap-bold text-gray-900 text-center">
                {item.title}
              </h3>
              <p className="text-center text-gray-500 font-asap-medium">
                {item.description}
              </p>
            </article>
          ))}
        </div>
      </section>
      <section
        ref={aboutRef}
        style={{
          backgroundImage: `url(${SolutionsBackgroundImage})`,
        }}
        className="h-screen bg-no-repeat bg-cover bg-fixed bg-center text-white flex flex-col justify-between md:flex-row"
      >
        <div className="flex justify-center items-center flex-grow md:w-1/2 md:flex-grow-0">
          <h2 className="font-asap-bold text-6xl text-center px-2 md:px-4">
            About Us
          </h2>
        </div>
        <div className="bg-purple-900/50  px-4 py-10 md:w-1/2 md:px-16 flex  justify-center items-center text-center text-xl lg:text-2xl font-asap-medium">
          <h3>
            Technolab Digital is a privately owned group of technology companies
            with a focus on digital products and services. The company works on
            projects that have long-term potential and provides them with strong
            support for growth. We are constantly opening up new opportunities
            through innovative business models.More than that, we integrate
            people and mobile content through technology.
          </h3>
        </div>
      </section>
      <section className=" py-16 ">
        <div className="space-y-6 max-w-xl mx-auto px-4">
          <h1 className="text-6xl font-asap-bold text-gray-700 text-center">
            Mission
          </h1>
          <h3 className="text-2xl font-asap-medium text-gray-500 text-center">
            Our Mission is to maximize the business profitability of our
            partners by providing effective localized contents and leveraging
            our market experience to create innovative services and solutions.
          </h3>
        </div>
      </section>
      <section className=" py-16 bg-purple-900/30">
        <div className="space-y-6 max-w-xl mx-auto text-white px-4">
          <h1 className="text-6xl font-asap-bold  text-center">Vision</h1>
          <h3 className="text-2xl font-asap-medium  text-center">
            Our Vision is to be the leader in Ethiopia in mobile VAS Managed
            Services, Mobile Content and VAS Solutions.
          </h3>
        </div>
      </section>
      <section className="px-8 md:px-16 py-10">
        <div className="my-8">
          <h2 className="text-4xl font-asap-bold text-purple-800 ">
            Send us a message
          </h2>
        </div>
        <div className="flex">
          <form className="text-gray-700 space-y-6 flex flex-col w-full md:w-1/2">
            <div className="flex flex-col md:flex-row  space-y-6 md:space-x-2 md:space-y-0">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                className="border rounded focus:outline-none px-4 py-3 flex-grow"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="border rounded focus:outline-none px-4 py-3 flex-grow"
              />
            </div>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              className="border rounded focus:outline-none px-4 py-3"
            />
            <textarea
              name="message"
              cols="30"
              rows="10"
              placeholder="Message"
              className="border rounded focus:outline-none px-4 py-3 resize-none"
            ></textarea>
            <input
              type="submit"
              value="Submit"
              className="bg-purple-800 text-white px-6 py-3 rounded shadow cursor-pointer hover:bg-purple-700"
            />
          </form>
          <div className=" justify-center items-center w-1/2 hidden md:flex">
            <img src={LogoIcon} alt="Website logo icon" className="h-[500px]" />
          </div>
        </div>
      </section>
      <footer
        ref={contactsRef}
        style={{
          backgroundImage: `url(${FooterBackgroundImage})`,
          backgroundPosition: "0 50%",
        }}
        className="h-[calc(100vh-150px)] md:h-[calc(100vh-200px)] bg-no-repeat bg-black text-white flex flex-col justify-between  px-8 md:px-16 py-10"
      >
        <div className="flex flex-col md:flex-row">
          <h1 className="text-3xl font-asap-bold mb-6 md:w-1/3 md:text-4xl">
            Contacts
          </h1>
          <div className="space-y-4 md:space-y-8 md:w-2/3">
            <div className="text-lg md:text-xl font-asap-bold  uppercase w-max ">
              <h2 className="text-orange-400">Addis Ababa</h2>
            </div>
            <div className="space-y-2">
              <h3 className="text-gray-400 text-lg font-asap-bold">
                Headquarters
              </h3>
              <p className="font-asap-medium text-xl">
                Bethel,Tujinema Building 02-007,Woreda 7 , Addis Ababa ,
                Ethiopia.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-gray-400 text-lg font-asap-bold">E-mail</h3>
              <p className="font-asap-medium text-xl">Hello@technolabet.com</p>
            </div>
          </div>
        </div>
        <p className="font-asap-medium text-lg text-gray-600 text-center">
          Copyright © {new Date().getFullYear()}. All rights reserved.
        </p>
      </footer>
    </main>
  );
};

export default Home;
